import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { firestore } from './firebase';

dayjs.extend(customParseFormat);

/**
 * Day index mapping for Monday–Friday.
 *  - 0 = Monday
 *  - 1 = Tuesday
 *  - 2 = Wednesday
 *  - 3 = Thursday
 *  - 4 = Friday
 *  - 5 = "No Schedule" bucket (handled separately in logic)
 */
const DAY_INDEX_MAP = {
  monday: 0,
  tuesday: 1,
  wednesday: 2,
  thursday: 3,
  friday: 4,
};

/**
 * Valid enrollment statuses that are considered active.
 *  - "enrolled"
 *  - "pending"
 */
const VALID_ENROLLMENT_STATUSES = ['enrolled', 'pending'];

/**
 * Fetches students from Firestore for the given organization, filters
 * them based on the specified date, and categorizes them by age group
 * and schedule. Returns counts for Monday–Friday plus a "No Schedule" column.
 *
 * @param {string} organizationId - The Firestore doc ID for the organization.
 * @param {string} dateString - Reference date in "YYYY-MM-DD" format.
 * @returns {Promise<Object>} An object with counts for each age group, e.g.:
 * {
 *   under2:     [0, 0, 0, 0, 0, 0],
 *   over2:      [0, 0, 0, 0, 0, 0],
 *   dobUnknown: [0, 0, 0, 0, 0, 0],
 *   total:      [0, 0, 0, 0, 0, 0]
 * }
 */
export async function getWeeklyAttendance(organizationId, dateString) {
  // Validate inputs (production code should handle bad input gracefully)
  if (!organizationId || typeof organizationId !== 'string') {
    throw new Error('Invalid organizationId');
  }
  if (!dateString || !dayjs(dateString, 'YYYY-MM-DD', true).isValid()) {
    throw new Error('Invalid dateString format; expected "YYYY-MM-DD"');
  }

  // Parse the reference date
  const referenceDate = dayjs(dateString, 'YYYY-MM-DD');

  try {
    // 1) Query Firestore for all enrolled students under this organization
    const snapshot = await firestore().collection('organizations').doc(organizationId).collection('students').get();

    if (snapshot.empty) {
      // No students found; return empty counts
      return createEmptyAttendance();
    }

    // 2) Prepare the structure to hold attendance counts
    const attendance = createEmptyAttendance();

    // 3) Process each student document
    for (const doc of snapshot.docs) {
      const student = doc.data();
      const enrollment = student.enrollment;

      if (!enrollment) {
        // 3a) Add students with no enrollment to the "no schedule" bucket
        const ageGroup = getAgeGroup(student, referenceDate);
        incrementCount(attendance, ageGroup, 5);
        continue;
      }

      // 3b) Look if enrollment status is enrolled or pending
      if (!VALID_ENROLLMENT_STATUSES.includes(enrollment.status)) {
        continue;
      }

      // 3c) Check if the student is active on the referenceDate
      if (!isActiveOnDate(enrollment, referenceDate)) {
        continue;
      }

      // 3d) Determine the age group
      const ageGroup = getAgeGroup(student, referenceDate);

      // 3e) Tally their schedule
      tallySchedule(enrollment, ageGroup, attendance);
    }

    return attendance;
  } catch (error) {
    console.error('Error in getWeeklyAttendance:', error);
    throw new Error('Failed to fetch and process attendance data.');
  }
}

/**
 * Creates the base attendance object with zeroed arrays.
 * Each array has 6 slots: Monday–Friday + No Schedule (index 5).
 */
function createEmptyAttendance() {
  return {
    under2: [0, 0, 0, 0, 0, 0],
    over2: [0, 0, 0, 0, 0, 0],
    dobUnknown: [0, 0, 0, 0, 0, 0],
    total: [0, 0, 0, 0, 0, 0],
  };
}

/**
 * Determines if an enrollment is active on the given date.
 * - If startDate exists and referenceDate < startDate => not active.
 * - If endDate exists and referenceDate > endDate => not active.
 * - If startDate is missing => considered active from the beginning of time
 * - If endDate is missing => considered active indefinitely
 * - Otherwise, it's active.
 */
function isActiveOnDate(enrollment, referenceDate) {
  const { startDate, unenrolled } = enrollment;

  // Firestore Timestamp checks
  if (startDate) {
    let startDateTime;
    if (startDate.toDate) {
      startDateTime = startDate.toDate();
    } else if (typeof startDate._seconds === 'number') {
      startDateTime = new Date(startDate._seconds * 1000);
    }

    if (startDateTime) {
      const start = dayjs(startDateTime);
      if (referenceDate.isBefore(start)) return false;
    }
  }
  if (unenrolled && unenrolled.date) {
    const end = dayjs(unenrolled.date, 'MM/DD/YYYY', true);
    if (end.isValid() && referenceDate.isAfter(end)) return false;
  }

  return true;
}

/**
 * Returns the correct age group for the student:
 * - "dobUnknown" if birthday is missing
 * - "under2" if child < 2 years old on referenceDate
 * - "over2" otherwise
 */
function getAgeGroup(student, referenceDate) {
  if (!student.birthday) {
    return 'dobUnknown';
  }

  let birthdayDate;

  if (student.birthday.toDate) {
    birthdayDate = student.birthday.toDate();
  } else if (typeof student.birthday._seconds === 'number') {
    birthdayDate = new Date(student.birthday._seconds * 1000);
  } else {
    return 'dobUnknown';
  }

  const birthday = dayjs(birthdayDate);
  const ageInYears = referenceDate.diff(birthday, 'years', true);
  return ageInYears >= 2 ? 'over2' : 'under2';
}

/**
 * Tally the schedule for the given enrollment in the correct attendance buckets.
 * If no scheduledDays exist or none map to Monday–Friday, increment the "No Schedule" column (index 5).
 */
function tallySchedule(enrollment, ageGroup, attendance) {
  const { scheduledDays } = enrollment;
  // If scheduledDays is missing or empty, mark as no schedule
  if (!Array.isArray(scheduledDays) || scheduledDays.length === 0) {
    incrementCount(attendance, ageGroup, 5);
    return;
  }

  let matchedAnyWeekday = false;
  for (const dayObj of scheduledDays) {
    const dayName = (dayObj.day || '').toLowerCase(); // e.g. "wednesday"
    const dayIndex = DAY_INDEX_MAP[dayName]; // e.g. 2 for Wednesday

    if (dayIndex !== undefined) {
      incrementCount(attendance, ageGroup, dayIndex);
      matchedAnyWeekday = true;
    }
  }

  // If none matched Monday–Friday, treat as "No Schedule"
  if (!matchedAnyWeekday) {
    incrementCount(attendance, ageGroup, 5);
  }
}

/**
 * Helper to increment both the ageGroup bucket and the "total" bucket
 * at the specified index (0–4 for M–F, 5 for No Schedule).
 */
function incrementCount(attendance, ageGroup, index) {
  attendance[ageGroup][index] += 1;
  attendance.total[index] += 1;
}
