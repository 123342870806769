import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line no-restricted-imports
import { Button, Checkbox, Form, Header, Icon, Modal, Popup, Segment } from 'semantic-ui-react';
// TODO: Lodash should no longer be used here
// eslint-disable-next-line no-restricted-imports
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import MaskedInput from 'react-text-mask';
import Validator from 'validator';

// Import components
import LocationTypePicker from '../../Locations/LocationTypePicker';
import InlineError from '../../Messages/InlineError';
import ShowError from '../../Messages/ShowError';
import StatePicker from '../../Shared/StatePicker';
import HourPicker from './HourPicker';
import LocationInput from './LocationInput';
import TimezonePicker from './TimezonePicker';

// Import utils
import { phoneNumberFormat, phoneNumberParse } from '../../../helpers/utils';

// Import actions
import { RoomCreateMutation, useRoomCreateMutation } from '@wonderschool/common-food-program-data-access';
import {
  locationSelectionCleared,
  organizationAddLocation,
  organizationUpdateLocation,
} from '../../../redux/actions/locationActions';
import { roomAdded } from '../../../redux/actions/roomActions';

interface LocationFormProps {
  onClose?: (status?: string) => void;
}

interface LocationData {
  id: string;
  name: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipcode: string;
  geometry: {
    lat: string;
    long: string;
  };
  phone: string;
  locationType: string;
  enabled: boolean;
  active: boolean;
  timezone: string;
  capacityGoal: string;
  licenseCapacity: string;
  businessHoursStart: number;
  businessHoursEnd: number;
  primary: boolean;
  ein: string;
  overnightCare: boolean;
}

const LocationForm: FC<LocationFormProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currentOrganization = useSelector((state: any) => state.organizations.currentOrganization);
  const locations = useSelector((state: any) => state.locations);

  const [data, setData] = useState<LocationData>({
    id: '',
    name: currentOrganization?.name || '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipcode: '',
    geometry: {
      lat: '',
      long: '',
    },
    phone: '',
    locationType: currentOrganization?.programType || '',
    enabled: true,
    active: true,
    timezone: '',
    capacityGoal: '',
    licenseCapacity: '',
    businessHoursStart: -62167176238000,
    businessHoursEnd: -62167136638000,
    primary: locations?.length === 0 ? true : false,
    ein: '',
    overnightCare: false,
  });

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [disableModalOpen, setDisableModalOpen] = useState(false);

  const [roomCreateMutation] = useRoomCreateMutation();

  useEffect(() => {
    const selectedLocation = locations?.selectedLocation;

    if (!_.isEmpty(selectedLocation?.id) && data.id !== selectedLocation.id) {
      const { phone, ...rest } = selectedLocation;
      setData((prev) => ({
        ...prev,
        phone: phoneNumberParse(phone),
        ...rest,
      }));
    }
  }, [locations?.selectedLocation, data.id]);

  useEffect(() => {
    return () => {
      dispatch(locationSelectionCleared());
    };
  }, [dispatch]);

  const onChange = (e: any, { name, value, type, checked }: any) => {
    if (type === 'checkbox') {
      value = checked;
    }
    setData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => _.omit(prev, name));
  };

  const onLocationSelected = ({ formatted }: any) => {
    const { address1, city, state, zipcode, timezone, geometry } = formatted;

    setData((prev) => ({
      ...prev,
      address1,
      city,
      state,
      zipcode,
      timezone: timezone?.value,
      geometry: {
        lat: geometry.location.lat(),
        long: geometry.location.lng(),
      },
    }));
  };

  const maskedOnChange = (e: any) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    setErrors((prev) => _.omit(prev, e.target.name));
  };

  const tryToggleLocationEnabled = (e: any, { name, checked }: any) => {
    if (checked === false) {
      setDisableModalOpen(true);
      return;
    }

    setData((prev) => ({
      ...prev,
      enabled: checked,
    }));
    setErrors((prev) => _.omit(prev, name));
  };

  const forceDisableLocation = () => {
    setData((prev) => ({
      ...prev,
      enabled: false,
    }));
    setErrors((prev) => _.omit(prev, 'enabled'));
    setDisableModalOpen(false);
  };

  const validate = (data: LocationData) => {
    const errors: Record<string, string> = {};
    const locationEnabled = data.enabled;

    if (!data.name) errors.name = t('Name is required');

    if (!locationEnabled) return errors;

    if (!data.address1) errors.address1 = t('Address is required');
    if (!data.city) errors.city = t('City is required');
    if (!data.state) errors.state = t('State is required');
    if (!data.zipcode) errors.zipcode = t('Zip code is required');

    if (!data.phone) {
      errors.phone = t('Phone is required');
    } else {
      if (!Validator.isMobilePhone(data.phone, 'en-US')) errors.phone = t('common.invalidPhone');
    }
    if (!data.locationType) errors.locationType = t('Location type is required');
    if (!data.businessHoursStart) errors.businessHoursStart = t('Opening time is required');
    if (!data.businessHoursEnd) errors.businessHoursEnd = t('Closing time is required');
    if (!data.timezone) errors.timezone = t('Timezone is required');
    if (!data.capacityGoal) errors.capacityGoal = t('Capacity goal is required');
    if (Number(data.capacityGoal) <= 0) errors.capacityGoal = t('Capacity goal has to be greater than 0');
    if (!data.licenseCapacity) errors.licenseCapacity = t('License capacity has to be greater than 0');
    if (Number(data.licenseCapacity) <= 0) errors.licenseCapacity = t('License capacity has to be greater than 0');

    if (!data.overnightCare && data.businessHoursStart && data.businessHoursEnd) {
      if (data.businessHoursStart >= data.businessHoursEnd) {
        errors.businessHoursEnd = t('Closing time should be after opening time');
      }
    }

    return errors;
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const validationErrors = validate(data);
    setErrors(validationErrors);

    if (_.isEmpty(validationErrors)) {
      const { id, phone, ...rest } = data;
      const programType = currentOrganization?.programType;

      setLoading(true);
      const formData = {
        phone: phoneNumberFormat(phone),
        ...rest,
      };

      try {
        if (id) {
          if (currentOrganization?.id) {
            await dispatch(
              organizationUpdateLocation(currentOrganization.id, {
                id,
                ...formData,
              }) as any
            );
            onClose?.('updated');
          }
        } else {
          if (currentOrganization?.id) {
            await dispatch(organizationAddLocation(currentOrganization.id, formData, programType) as any);

            if (programType === 'familyChildCare') {
              const locationId = `${currentOrganization.id}-primaryLocation`;

              roomCreateMutation({
                variables: {
                  input: {
                    locationIdentifier: {
                      firebaseId: locationId,
                    },
                    name: t('Primary Room'),
                    enabled: true,
                    ageRange: {
                      from: {
                        months: 0,
                        years: 0,
                      },
                      to: {
                        months: 0,
                        years: 0,
                      },
                    },
                  },
                },
                onCompleted: (response: RoomCreateMutation) => {
                  dispatch(
                    roomAdded({
                      organization: currentOrganization.id,
                      location: locationId,
                      name: t('Primary Room'),
                      id: response.roomCreate.firebaseId || '',
                      ageRange: {
                        from: {
                          months: 0,
                          years: 0,
                        },
                        to: {
                          months: 0,
                          years: 0,
                        },
                      },
                      enabled: true,
                      createdAt: new Date().toISOString(),
                    })
                  );
                  onClose?.('added');
                },
                onError: (error: any) => {
                  console.error('roomCreateMutation error', error);
                  setErrors({ [t('Unable to Add')]: error.message });
                },
              });
            }
          }
        }
      } catch (error: any) {
        setLoading(false);
        setErrors({ [t('Unable to Add')]: error.message });
      }
    }
  };

  const locationEnabled = data.enabled;
  const programType = currentOrganization?.programType;

  return (
    <Segment basic textAlign="left">
      <ShowError errors={errors} />
      <Form id="location-form" onSubmit={onSubmit} loading={loading} noValidate autoComplete="off">
        <Form.Field error={!!errors.name}>
          <Form.Input
            required
            type="text"
            id="name"
            name="name"
            label={t('common.name')}
            placeholder={t('common.name')}
            value={data.name}
            onChange={onChange}
            autoComplete="off"
          />
          {errors.name && <InlineError text={errors.name} />}
        </Form.Field>

        <Form.Group widths="equal">
          <Form.Field error={!!errors.address1}>
            <LocationInput
              required={locationEnabled}
              id="address1"
              name="address1"
              label={t('Address 1')}
              value={data.address1}
              onChange={onChange}
              onLocationSelected={onLocationSelected}
              innerLabel={t('Address Line 1')}
            />
            {errors.address1 && <InlineError text={errors.address1} />}
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field error={!!errors.address2}>
            <Form.Input
              type="text"
              id="address2"
              name="address2"
              label={t('Address 2')}
              placeholder={t('Address Line 2')}
              value={data.address2}
              onChange={onChange}
            />
            {errors.address2 && <InlineError text={errors.address2} />}
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field error={!!errors.city}>
            <Form.Input
              required={locationEnabled}
              type="text"
              id="city"
              name="city"
              label={t('City')}
              placeholder={t('City')}
              value={data.city}
              onChange={onChange}
            />
            {errors.city && <InlineError text={errors.city} />}
          </Form.Field>

          <Form.Field error={!!errors.state}>
            <Form.Field
              required={locationEnabled}
              id="state"
              name="state"
              label={t('common.state')}
              control={StatePicker}
              placeholder={t('common.state')}
              value={data.state}
              selection
              search
              onChange={onChange}
            />
            {errors.state && <InlineError text={errors.state} />}
          </Form.Field>

          <Form.Field error={!!errors.zipcode}>
            <Form.Input
              required={locationEnabled}
              type="text"
              id="zipcode"
              name="zipcode"
              label={t('Zip code')}
              placeholder={t('Zip code')}
              value={data.zipcode}
              onChange={onChange}
              maxLength="5"
            />
            {errors.zipcode && <InlineError text={errors.zipcode} />}
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field error={!!errors.phone}>
            <Form.Field
              required={locationEnabled}
              type="text"
              id="phone"
              name="phone"
              label={t('common.phone')}
              placeholder={t('common.phone')}
              control={MaskedInput}
              mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
              value={data.phone}
              guide={false}
              onChange={maskedOnChange}
            />
            {errors.phone && <InlineError text={errors.phone} />}
          </Form.Field>
        </Form.Group>

        {(programType === null || (programType && programType !== 'familyChildCare')) && (
          <Form.Field error={!!errors.locationType}>
            <Form.Field
              translator={t}
              isForm
              required={locationEnabled}
              id="locationType"
              name="locationType"
              label={t('Location Type')}
              control={LocationTypePicker}
              placeholder="Select location type"
              value={data.locationType}
              selection
              search
              onChange={onChange}
            />
            {errors.locationType && <InlineError text={errors.locationType} />}
          </Form.Field>
        )}

        <Header as="h3" dividing>
          {t('License Information')}
        </Header>

        <Form.Group widths="equal">
          <Form.Field error={!!errors.licenseCapacity}>
            <Form.Input
              required={locationEnabled}
              type="number"
              min={0}
              id="licenseCapacity"
              name="licenseCapacity"
              label={t('License Capacity')}
              placeholder={t('How many students are you licensed for?')}
              value={data.licenseCapacity}
              onChange={onChange}
            />
            {errors.licenseCapacity && <InlineError text={errors.licenseCapacity} />}
          </Form.Field>

          <Form.Field error={!!errors.capacityGoal}>
            <Form.Input
              required={locationEnabled}
              type="number"
              min={0}
              id="capacityGoal"
              name="capacityGoal"
              label={t('Capacity Goal')}
              placeholder={t('What is your capacity goal?')}
              value={data.capacityGoal}
              onChange={onChange}
            />
            {errors.capacityGoal && <InlineError text={errors.capacityGoal} />}
          </Form.Field>
        </Form.Group>

        <Header as="h3" dividing>
          {'Tax Information'}
        </Header>

        <Form.Group widths="equal">
          <Form.Field error={!!errors.ein}>
            <Form.Input
              type="number"
              min={0}
              id="ein"
              name="ein"
              label={t('EIN Number')}
              placeholder={t('EIN Number')}
              value={data.ein}
              onChange={onChange}
            />
            {errors.ein && <InlineError text={errors.ein} />}
          </Form.Field>
        </Form.Group>

        <Header as="h3" dividing>
          {t('Business Hours')}
        </Header>

        <Form.Group widths="equal">
          <Form.Field>
            <Form.Checkbox
              checked={data.overnightCare}
              id="overnightCare"
              name="overnightCare"
              label={t('My business runs overnight')}
              onChange={onChange}
              toggle
            />
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field error={!!errors.businessHoursStart}>
            <Form.Field
              required={locationEnabled}
              id="businessHoursStart"
              name="businessHoursStart"
              label={t('Opening Time')}
              control={HourPicker}
              placeholder={t('What time do you open?')}
              value={data.businessHoursStart}
              selection
              search
              onChange={onChange}
            />
            {errors.businessHoursStart && <InlineError text={errors.businessHoursStart} />}
          </Form.Field>

          <Form.Field error={!!errors.businessHoursEnd}>
            <Form.Field
              required={locationEnabled}
              id="businessHoursEnd"
              name="businessHoursEnd"
              label={t('Closing Time')}
              control={HourPicker}
              placeholder={t('What time do you close?')}
              value={data.businessHoursEnd}
              selection
              search
              onChange={onChange}
            />
            {errors.businessHoursEnd && <InlineError text={errors.businessHoursEnd} />}
          </Form.Field>
        </Form.Group>

        <Form.Field error={!!errors.timezone}>
          <Form.Field
            translator={t}
            isForm
            required={locationEnabled}
            id="timezone"
            name="timezone"
            label={t('Timezone')}
            control={TimezonePicker}
            placeholder="Select a timezone"
            value={data.timezone}
            selection
            search
            onChange={onChange}
          />
          {errors.timezone && <InlineError text={errors.timezone} />}
        </Form.Field>

        {false && (
          <Form.Field error={!!errors.enabled}>
            <label>
              {t('Location enabled?')}
              <Popup
                content={t(
                  "Locations are enabled as default. This is a flag for you to keep track of your locations. Disabling it won't impact anything."
                )}
                position="right center"
                offset={[4, 0]}
                trigger={<Icon name="info circle" />}
              />
            </label>
            <Form.Radio
              toggle
              id="enabled"
              name="enabled"
              onChange={tryToggleLocationEnabled}
              control={Checkbox}
              checked={data.enabled}
            />
            {errors.enabled && <InlineError text={errors.enabled} />}
          </Form.Field>
        )}

        <Modal open={disableModalOpen}>
          <Modal.Header>{t('Are you sure?')}</Modal.Header>
          <Modal.Content>
            <Modal.Description>{t('Are you sure you want to disable this location?')}</Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button color="red" onClick={() => setDisableModalOpen(false)}>
              {t('common.no')}
            </Button>
            <Button content="Yes" labelPosition="right" icon="checkmark" onClick={forceDisableLocation} positive />
          </Modal.Actions>
        </Modal>

        <br />

        <Form.Group>
          <Form.Button primary content={data.id ? t('common.update') : t('Save')} />
          <Form.Button
            basic
            content={t('common.cancel')}
            onClick={(e) => {
              if (e) e.preventDefault();
              if (onClose) onClose();
            }}
          />
        </Form.Group>
      </Form>
    </Segment>
  );
};

export default LocationForm;
